import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "icon"
}
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = {
  value: "",
  disabled: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 1,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

import { ref } from 'vue';
    import { useAnimation } from '@composables/animation';

    
export default {
  __name: 'BaseSelect',
  props: /*@__PURE__*/_mergeModels({
        isError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        selectOne: {
            type: String,
            default: 'Select One',
        },
        error: {
            type: String,
            required: true,
        },
        forceAnimation: {
            type: Boolean,
            required: false,
            default: true,
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

    

    const touched = ref(false);

    const { animated } = useAnimation();

    const model = _useModel(__props, "modelValue");

    const updateTouched = () => {
        touched.value = true;
    };


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-select field", { error: __props.isError && touched.value, animated: _unref(animated) && __props.forceAnimation, valued: model.value && model.value !== '' }])
  }, [
    (_ctx.$slots.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon")
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      id: __props.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((model).value = $event)),
      name: __props.name,
      onFocus: updateTouched
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString(_unref(animated) && __props.forceAnimation ? '' : __props.label), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: `option_${index}`,
          value: option.value
        }, _toDisplayString(option.label), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2), [
      [_vModelSelect, model.value]
    ]),
    _createElementVNode("label", { for: __props.name }, _toDisplayString(__props.label), 9, _hoisted_5),
    (__props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(__props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

}