import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"
import _imports_0 from '@public/img/icons/icon-drop-50.png'
import _imports_1 from '@public/img/activity-log/receipt_approved.svg'


const _hoisted_1 = { class: "promotion-header-section" }
const _hoisted_2 = { class: "image-container" }
const _hoisted_3 = ["srcset"]
const _hoisted_4 = ["srcset", "alt"]
const _hoisted_5 = { class: "mini-dash" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "content-container" }
const _hoisted_8 = { class: "promotion-headline-copy upper" }
const _hoisted_9 = { class: "promotion-header-body-copy" }
const _hoisted_10 = {
  key: 0,
  class: "promotion-header-body-copy"
}
const _hoisted_11 = { class: "earn-entries-container" }
const _hoisted_12 = { class: "border-bottom mb-s" }
const _hoisted_13 = { class: "earn-entry-item border-bottom" }
const _hoisted_14 = { class: "item-copy mb-s" }
const _hoisted_15 = { class: "earn-entry-item" }
const _hoisted_16 = {
  key: 1,
  class: "small-text"
}

import { computed } from 'vue';
    import { useComponent } from '@composables/component';

    
export default {
  __name: 'PromotionHeader',
  props: {
        promotion: {
            type: String,
            required: true,
        },
        redeemItem: {
            type: String,
            required: true,
        },
    },
  setup(__props) {

    const { t, vT, store } = useComponent(`promotion_${__props.promotion}`);
    const app = computed(() => store.state.app);

    

    const hasIcon = computed(() => t('assets.mini_dash.image_src'));
    const authenticated = computed(() => store.getters['profile/loggedIn']);
    const promotionData = computed(() => store.getters['promotions/promotionData'](__props.promotion));
    const entries = computed(() => store.getters['promotions/userSweepsCount'](`bluetriton_${__props.promotion}`) || 0);

    const dayRemaining = computed(() => {
        const { now } = app.value;
        const end = new Date(promotionData?.value.expired);
        const diff = end - now;
        return Math.floor(diff / (1000 * 60 * 60 * 24));
    });

    const addAbbrRules = computed(() => t('earn_entries.abbreviated_rules'));

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("picture", null, [
        _createElementVNode("source", {
          media: "(min-width: 768px)",
          srcset: _unref(t)(`assets.header.image_src_large`)
        }, null, 8, _hoisted_3),
        _createElementVNode("img", {
          srcset: _unref(t)(`assets.header.image_src_small`),
          alt: _unref(t)(`assets.header.image_alt_text`)
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          (authenticated.value)
            ? _withDirectives((_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass({ 'margin-right-10': !hasIcon.value })
              }, null, 2)), [
                [_unref(vT), { key: 'entries_remaining', entries: entries.value }]
              ])
            : _createCommentVNode("", true),
          (hasIcon.value)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _unref(t)(`assets.mini_dash.image_src`),
                alt: ""
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("span", null, null, 512), [
            [_unref(vT), { key: 'time_period', count: dayRemaining.value }]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _withDirectives(_createElementVNode("h2", _hoisted_8, null, 512), [
        [_unref(vT), 'headline_copy']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_9, null, 512), [
        [_unref(vT), 'body_copy']
      ]),
      (!authenticated.value)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, null, 512)), [
            [_unref(vT), 'authenticated.body_copy']
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _withDirectives(_createElementVNode("h3", _hoisted_12, null, 512), [
          [_unref(vT), 'earn_entries.how_to_earn']
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "icon" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ], -1)),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_unref(vT), 'earn_entries.redeem_drops']
            ]),
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_unref(vT), 'earn_entries.redeem_drops_copy']
            ])
          ]),
          (authenticated.value)
            ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: __props.redeemItem,
                class: "btn"
              }, null, 8, ["to"])), [
                [_unref(vT), 'cta_redeem']
              ])
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "icon" }, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: ""
            })
          ], -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["item-copy", { 'mb-s': authenticated.value }])
          }, [
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_unref(vT), 'earn_entries.upload_receipt']
            ]),
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_unref(vT), 'earn_entries.upload_receipt_copy']
            ])
          ], 2),
          (authenticated.value)
            ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/earn",
                class: "btn"
              }, null, 512)), [
                [_unref(vT), 'cta_upload']
              ])
            : _createCommentVNode("", true),
          (addAbbrRules.value)
            ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_16, null, 512)), [
                [_unref(vT), { key: 'earn_entries.abbreviated_rules', rulesUrl: promotionData.value?.siteUrl }]
              ])
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

}