// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments

const
    PromotionLandingPage = async () => import(
        /* webpackChunkName: 'promotion' */
        '../views/promotion/PromotionLandingPage.vue'
    ),
    PromotionsListPage = async () => import(
        /* webpackChunkName: 'promotion' */
        '../views/promotion/PromotionsListPage.vue'
    ),
    PromotionPlayPage = async () => import(
        /* webpackChunkName: 'promotion' */
        '../views/promotion/PromotionPlayPage.vue'
    ),
    PromotionIWPage = async () => import(
        /* webpackChunkName: 'promotion iw game' */
        '../views/promotion/PromotionIWPage.vue'
    )
    ;

export default [
    { path: '/promotions', component: PromotionsListPage, meta: { public: true } },
    { path: '/promotion/:promotion', component: PromotionLandingPage, meta: { public: true }, props: true },
    { path: '/play/:promotion', component: PromotionPlayPage, meta: { public: true }, props: true },
    { path: '/game/:promotion', component: PromotionIWPage, props: true },
];
