
    import { computed } from 'vue';
    import { useComponent } from '@composables/component';
    import { PromotionOptin } from '@components/promotions';
    import BaseModal from './BaseModal';

    export default {
        name: 'PromotionOptInModal',

        components: {
            PromotionOptin,
        },

        extends: BaseModal,

        props: {
            promos: {
                type: Array,
                required: true,
            },
        },

        setup () {
            const { t, store } = useComponent(['promotions', 'buttons']);
            const activePromotions = computed(() => store.getters['promotions/needsOptin']);

            return { t, activePromotions, store };
        },

        data () {
            return {
                modalName: 'promotion-optin',
                deploy_path: [],
            };
        },

        methods: {
            updateValue (data) {
                this.deploy_path.push(data.name);
            },
            sendOptins () {
                if (this.deploy_path.length) {
                    this.store.dispatch('promotions/sendOptins', this.deploy_path);
                }

                this.closeModal();
            },
        },
    };
