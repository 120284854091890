
import config from 'rocketship-config';
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    { brands } = {"_public":true,"brands":[{"brand_name":"arrowhead","url":"arrowhead","lp_id":"arrowhead","formatted":"Arrowhead","short":["a","ah"],"meta_tracking":1559367464455615,"active":1},{"brand_name":"deerpark","url":"deerpark","lp_id":"deer","formatted":"Deer Park","short":["dp"],"meta_tracking":214191563060347,"active":1},{"brand_name":"icemountain","url":"icemountain","lp_id":"ice","formatted":"Ice Mountain","short":["im"],"meta_tracking":223352885490785,"active":1},{"brand_name":"ozarka","url":"ozarka","lp_id":"ozarka","formatted":"Ozarka","short":["oz"],"meta_tracking":1378266155691157,"active":1},{"brand_name":"purelife","url":"purelife","lp_id":"pure","formatted":"Pure Life","short":["pl"],"meta_tracking":751400232650294,"active":1},{"brand_name":"polandspring","url":"polandspring","lp_id":"poland","formatted":"Poland Spring","short":["ps"],"meta_tracking":2996036443781632,"active":1},{"brand_name":"saratoga","url":"saratoga","lp_id":"saratoga","formatted":"Saratoga","short":["sa"],"active":0},{"brand_name":"splash","url":"splash","lp_id":"splash","formatted":"Splash Refresher","short":["sp"],"meta_tracking":756962224955686,"active":1},{"brand_name":"zephyrhills","url":"zephyrhills","lp_id":"zephyr","formatted":"Zephyrhills","short":["zh"],"meta_tracking":581542643298772,"active":1}]},
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    ),
    routes = brands.map((brand) => ({ path: `/${brand.url}`, component: LandingPage, name: `landing_${brand.brand_name}`, props: { brand: brand.brand_name, lpId: brand.lp_id }, meta: { public: true, activeInMaint: false } }));
export default [...routes];
