
    import { mapActions, mapMutations } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import BrandsSelector from '@components/BrandsSelector.vue';

    import BaseModal from './BaseModal';

    export default {
        name: 'BrandSelectionModal',

        components: {
            BrandsSelector,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['brand_selection', 'buttons']);
            return { t, vT };
        },

        data () {
            return {
                modalName: 'brand-selection',
                brand: '',
            };
        },

        methods: {
            ...mapActions({
                updateCurrentProfile: 'profile/updateCurrentProfile',
            }),
            ...mapMutations({
                updateLeaveCheck: 'ui/updateLeaveCheck',
            }),
            onSelection (brand) {
                this.brand = brand;
            },
            async submitBrand () {
                if (this.brand === '') {
                    return;
                }

                await this.updateCurrentProfile({ 'preferred_brand': this.brand });
                this.updateLeaveCheck(false);
                this.closeModal();
            },
        },
    };
