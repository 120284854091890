import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "main-section",
  role: "main"
}

import { computed, watch, onErrorCaptured, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18next } from '@composables/i18next';

    import TheHeader from '@components/TheHeader.vue';
    import TheFooter from '@components/TheFooter.vue';
    import HeaderNavigation from '@components/HeaderNavigation';
    import FixedNavigation from '@components/FixedNavigation';
    import IntroAnimation from '@components/IntroAnimation';

    import openModal, { SessionExpirationModal, StickyAuthenticatorModal, BrandSelectionModal } from './modals';

    import logClientError from './client-error';

    
export default {
  __name: 'App',
  setup(__props) {

    const { t } = useI18next(['global', 'cookie_gate']);

    const store = useStore();
    const ui = computed(() => store.state.ui);
    const profile = computed(() => store.state.profile);

    const router = useRouter();

    const loggedIn = computed(() => store.getters['profile/loggedIn']);
    const isSessionExpired = computed(() => store.getters['profile/isSessionExpired']);
    const checkLeaveStatus = computed(() => store.getters['ui/checkLeaveStatus']);
    const showAnimation = computed(() => loggedIn.value && profile.value.preferred_brand);

    const excludePages = new Set(['RewardDetail', 'RedeemConfirmationPage']);
    const includeNavigation = computed(() => {
        const inArray = excludePages.has(ui.value.pageName);
        return loggedIn.value && !inArray;
    });

    const excludeHeaderPages = new Set(['RedeemConfirmationPage']);
    const includeHeader = computed(() => {
        const inArray = excludeHeaderPages.has(ui.value.pageName);
        return !inArray;
    });

    watch(isSessionExpired, (newValue) => {
        if (newValue) {
            openSessionExpirationModal();
        }
    });

    watch(checkLeaveStatus, (newValue) => {
        if (newValue) {
            window.addEventListener('beforeunload', displayLeave);
        }
        else {
            window.removeEventListener('beforeunload', displayLeave);
        }
    });
    const displayLeave = (event) =>  {
        event.preventDefault();
        event.returnValue = true;
    };

    const notAllowedPages = ['prelaunch', 'expired', 'maintenance'];

    const logOut = () => store.dispatch('profile/logout');
    const openSessionExpirationModal = async () => {
        await openModal(SessionExpirationModal);
        // After the modal is closed.
        logOut();
        router.replace('/');
    };

    // If a fatal error occurs rendering any of our descendent components, try
    // to navigate to the error page to preserve some UX.
    onErrorCaptured((err, instance, info) => {
        router.push('/error');
        // Forward fatal Vue component errors, i.e. those that caused us to display
        // the error page here, to './client-error' and its server-side logging.
        logClientError(err, { fatal: true });
    });

    onMounted(async () => {
        if (loggedIn.value && profile.value.preferred_brand === undefined) {
            openModal(BrandSelectionModal);
        }
        await store.dispatch('ui/getFeatureFlags');
        await store.dispatch('ui/getActiveUiElements');
    });


return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper", profile.value.preferred_brand])
  }, [
    _createVNode(TheHeader),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view, { class: "content" })
    ]),
    (!loggedIn.value && !notAllowedPages.includes(ui.value.pageName))
      ? (_openBlock(), _createBlock(_unref(StickyAuthenticatorModal), { key: 0 }))
      : _createCommentVNode("", true),
    (includeNavigation.value)
      ? (_openBlock(), _createBlock(_unref(FixedNavigation), { key: 1 }))
      : _createCommentVNode("", true),
    (ui.value.pageName === 'landing')
      ? (_openBlock(), _createBlock(TheFooter, { key: 2 }))
      : _createCommentVNode("", true),
    (includeHeader.value)
      ? (_openBlock(), _createBlock(_unref(HeaderNavigation), { key: 3 }))
      : _createCommentVNode("", true),
    (showAnimation.value)
      ? (_openBlock(), _createBlock(_unref(IntroAnimation), { key: 4 }))
      : _createCommentVNode("", true)
  ], 2))
}
}

}