
    import VeryfiLens from 'veryfi-lens-wasm/veryfi-lens';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { BaseFileInput } from '@components/form';
    import TheDrop from '@components/svgs/TheDrop.vue';

    import uploader from '../uploader';

    import BaseModal from './BaseModal';

    const { validatePartner } = require('../../../lib/veryfi');

    export default {
        name: 'ReceiptUploadModal',

        components: {
            BaseFileInput,
            TheDrop,
        },

        extends: BaseModal,

        setup () {
            const { t, vT } = useI18next(['file_uploader', 'buttons']);
            const store = useStore();
            return { t, vT, store };
        },

        data () {
            return {
                modalName: 'receipt-upload',
                flow: ['upload', 'preview', 'error', 'confirmation'],
                currentPage: 0,
                fileObject: '',
                uploadedReceipt: false,
                photoFileTypes: 'image/jpg, image/jpeg, image/png, application/pdf, image/gif',
                photoUploadSizeMax: 5 * 1024 * 1024,
                photoError: '',
                disabledBtn: false,
                previewImg: {},
                showVeryfi: false,
                rawImg: '',
                hasPdf: false,
                defaultPdfSrc: 'public/img/default_pdf_icon.png',
            };
        },

        methods: {
            async openScanner () {
                this.disabledBtn = true;
                this.showVeryfi = true;

                const { session, clientId } = await validatePartner(this.$store.state.app.veryfiClientId);
                await VeryfiLens.getDeviceData();
                await VeryfiLens.initWasm(session, clientId);

            },
            closeSuccessModel () {
                this.store.commit('profile/updateProfile', { receipt_limited: true });
                this.store.dispatch('profile/getUserReceipts', true);
                this.closeModal();
            },

            async captureImg () {
                const img = await VeryfiLens.captureWasm();

                this.rawImg = img;
                this.previewImg.photo = `data:image/jpeg;base64, ${img}`;

                const
                    mime = 'image/jpeg',
                    binaryRaw = atob(img);

                let
                    n = binaryRaw.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = binaryRaw.charCodeAt(n);
                }

                const newBinaryFile = new File([u8arr], 'temp', {
                    type: mime,
                });

                this.previewImg.file = newBinaryFile;

                this.currentPage++;
                this.showVeryfi = false;
                this.disabledBtn = false;
            },

            async openPreview (response) {
                this.disabledBtn = true;
                this.photoError = '';
                if (response.error) {
                    this.photoError = response.error;
                    this.disabledBtn = false;
                    return;
                }

                this.hasPdf = response.file.type.includes('pdf');

                this.previewImg = response;

                this.currentPage++;
                this.disabledBtn = false;
            },

            async uploadFile () {
                this.loading = true;
                this.photoError = '';

                try {
                    let veryfiData;

                    if (this.rawImg) {
                        const deviceData = await VeryfiLens.getDeviceData();
                        veryfiData = await uploader.veryfiProcessImage(this.rawImg, deviceData);
                    }

                    const submitData = await uploader.submit(this.previewImg, veryfiData);

                    if (submitData.data.awardQuantity) {
                        // temporarily add to profile so we know how many entries they have for the game
                        await this.store.commit('profile/updateProfile', { awardQuantity: submitData.data.awardQuantity });

                        // send to game page
                        this.$router.push('/game/deerparkallstar');
                    }
                    this.currentPage = 3;
                    this.loading = false;
                }
                catch (err) {
                    this.loading = false;
                    this.currentPage = 2;

                    console.log('DEBUG', { err });
                }
            },
        },
    };
