import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';

    
export default {
  __name: 'IntroAnimation',
  setup(__props) {

    const playing = ref(false);
    const completed = ref(false);

    const playHandler = () => {
        playing.value = true;
    };
    const endHandler = () => {
        completed.value = true;
    };


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["intro-animation", { completed: completed.value, playing: playing.value }]),
    "aria-hidden": "true"
  }, [
    _createElementVNode("video", {
      crossorigin: "anonymous",
      disablepictureinpicture: "",
      disableremoteplayback: "",
      muted: "",
      autoplay: "",
      playsinline: "",
      preload: "",
      class: "large-only",
      "aria-hidden": "true",
      onPlay: playHandler,
      onEnded: endHandler
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("source", {
        src: "https://d1hyi36gq7mg0j.cloudfront.net/bluetritonbrands/259980/videos/intro_animation-lg.mp4",
        type: "video/mp4"
      }, null, -1),
      _createElementVNode("source", {
        src: "https://d1hyi36gq7mg0j.cloudfront.net/bluetritonbrands/259980/videos/intro_animation-lg.webm",
        type: "video/webm"
      }, null, -1)
    ]), 32),
    _createElementVNode("video", {
      crossorigin: "anonymous",
      disablepictureinpicture: "",
      disableremoteplayback: "",
      muted: "",
      autoplay: "",
      playsinline: "",
      preload: "",
      class: "small-only",
      "aria-hidden": "true",
      onPlay: playHandler,
      onEnded: endHandler
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("source", {
        src: "https://d1hyi36gq7mg0j.cloudfront.net/bluetritonbrands/259980/videos/intro_animation-sm.mp4",
        type: "video/mp4"
      }, null, -1),
      _createElementVNode("source", {
        src: "https://d1hyi36gq7mg0j.cloudfront.net/bluetritonbrands/259980/videos/intro_animation-sm.webm",
        type: "video/webm"
      }, null, -1)
    ]), 32)
  ], 2))
}
}

}