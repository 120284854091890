/* These are turned into rocketship-validation objects.
 * Information on that is located in the readme for that.
 * https://github.com/rocketship-core/validator
 */
import range from 'lodash/range.js';
import isEmail from 'validator/lib/isEmail.js';
import config from 'rocketship-config';
const
    isLive = false,
    { maxFields, displayCAConfirm } = {"_public":true,"maxFields":1,"displayCAConfirm":true},
    // This is used to protect downstream systems with databases that can't handle
    // characters wider than three bytes, like FE2 and HW Analytics.
    // https://jiradc.helloworld.com/browse/SCU-144
    /* eslint-disable-next-line no-control-regex -- We match every character
        less than 4 bytes wide here, which does intentionally include control
        characters.
    */
    no4ByteChars = /^[\u{000000}-\u{00FFFF}]*$/u,

    noEmail = /^((?!@).)*$/,
    postalCode = /^\d{5}(?:-\d{4})?$/,
    // password requirements
    minChars = /^.{8,50}$/,
    minLetters = /[a-zA-Z]/,
    minNums = /[0-9]/,
    readyRefreshLength = /^.{10,12}$/,
    specialChars = /[!@#$%^&*\-_+=.]/;

const validators = {
    forgotPassword: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
    },
    login: {
        email: {
            required: true,
            external: [
                isEmail,
                {
                    blacklisted_chars: '”ɓ',
                },
            ],
            no4ByteChars,
        },
        plainTextPassword: {
            required: true,
        },
    },
    register: {
        first_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        last_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        email: {
            required: true,
            external: [
                isEmail,
                {
                    blacklisted_chars: '”ɓ',
                },
            ],
            no4ByteChars,
            checkEmail: function _checkEmail (value, key, obj, opts) {
                const validation = this;
                if (!isLive) {
                    return true;
                }

                if ((
                    /(@helloworld.com|@eprize.com|merkleinc.com|@merkle.com)/
                ).test(value)) {
                    validation.addError(key, 'ineligible_domain');
                    return false;
                }

                return true;
            },
        },
        zip: {
            required: true,
            postalCode,
        },
        password: {
            required: true,
            passwordRequirements (value, key) {
                const requirements = { minChars, minLetters, minNums, specialChars };
                const failedReqs = Object.keys(requirements).filter(req => !value?.match(requirements[req]));

                if (failedReqs.length) {
                    failedReqs.forEach((req) => this.addError(key, req, `failed ${req} test`));
                    return false;
                }

                return true;
            },
        },
        rules: {
            // `isChecked` on its own, like other guards, is implicitly optional, i.e.
            // it's as if we are saying "if we get a value, it must look like a checked
            // checkbox", and `required` means "we must get a value" (and `false` is a
            // value), so we use both `required` and `isChecked` to fully require this
            // field to be checked.
            required: true,
            isChecked: true,
        },
        program_optin: {
            required: false,
        },
        get_to_goal_optin: {
            required: false,
        },
        state: {
            required: true,
        },
        account_number: {
            required: false,
            checkForLengthIfExists (value = '') {
                if (value.length > 0) {
                    return value.match(readyRefreshLength);
                }
                return true;
            },
        },
    },
    passwordReset: {
        password: {
            required: true,
            passwordRequirements,
        },
    },

    ResetPassword: {
        password: {
            required: true,
            passwordRequirements (value, key) {
                const requirements = { minChars, minLetters, minNums, specialChars };
                const failedReqs = Object.keys(requirements).filter(req => !value?.match(requirements[req]));

                if (failedReqs.length) {
                    failedReqs.forEach((req) => this.addError(key, req, `failed ${req} test`));
                    return false;
                }

                return true;
            },
        },
    },
    viral: {
        to_email1: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            // These validators run on the first field, but are smart enough to check
            // for errors in all TO email addresses.
            // Note: `referringSelf` may only be validated client-side if `email` is a
            // public field in the profile config, and it's not by default.
            referringSelf (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj);

                const referredSelf = toEmailFields.find(([key, email]) => email === obj.selfEmail);

                if (referredSelf) {
                    const [selfKey] = referredSelf;
                    validation.addError(selfKey, 'REFERRED_SELF');
                    return false;
                }

                return true;
            },
            duplicateReferral (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj),
                    seen = {};
                const duplicate = toEmailFields.find(([key, email]) => {
                    if (email in seen) return true;
                    seen[email] = true;
                    return false;
                });
                if (duplicate) {
                    const [duplicateKey] = duplicate;
                    validation.addError(duplicateKey, 'DUPLICATE');
                    return false;
                }

                return true;
            },
        },
        to_name1: {
            required: true,
            no4ByteChars,
        },
    },
    faqContact: {
        first_name: {
            required: true,
        },
        email: {
            required: true,
            external: [isEmail],
        },
        question: {
            required: true,
        },
        issue_type: {
            required: true,
        },
    },
    updateName: {
        first_name: {
            no4ByteChars,
            noEmail,
            required: true,
        },
        last_name: {
            no4ByteChars,
            noEmail,
            required: true,
        },
    },
    updateAddress: {
        address1: {
            required: true,
            no4ByteChars,
            noEmail,
            noPoBox,
        },
        address2: {
            required: false,
            no4ByteChars,
            noEmail,
            noPoBox,
        },
        city: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        state: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        zip: {
            isZip: true,
        },
    },
    passwordMetaData: {
        requirements: { minChars, minLetters, minNums, specialChars },
    },
    updatePassword: {
        password: {
            required: true,
            passwordRequirements,
        },
        password_confirm: {
            required: true,
            matchesField: 'password',
        },
    },
    readyRefreshAdd: {
        email: {
            required: true,
            external: [isEmail],
        },
        zip: {
            isZip: true,
        },
        account_number: {
            required: true,
            readyRefreshLength,

        },
        hybris_id: {
            required: true,
        },
    },

    uploadKey: {
        type: {
            required: true,
            isIn: ['photo'],
        },
    },

    modServiceCallback: {
        externalIdentifier: {
            required: true,
        },
        inputBody: {
            required: true,
            isObject (value, key, obj) {
                const validation = this;

                if (!value || typeof value !== 'object') {
                    validation.addError(key, 'UNSUPPORTED_TYPE');
                    return false;
                }

                return true;
            },
        },
        outputBody: {
            required: true,
            hasStatus (value, key, obj) {
                const validation = this;

                if (!value || typeof value !== 'object') {
                    validation.addError(key, 'UNSUPPORTED_TYPE');
                    return false;
                }

                const { status } = value;

                let valid = true;

                if (!status || !Array.isArray(status)) {
                    validation.addError(`${key}.status`, 'UNSUPPORTED_TYPE');
                    valid = false;
                }
                else if (!status.length) {
                    validation.addError(`${key}.status`, 'VALIDATION');
                    valid = false;
                }

                return valid;
            },
        },
    },
    email_opt_out: {
        email: {
            required: true,
            external: [
                isEmail,
                {
                    blacklisted_chars: '”ɓ',
                },
            ],
            no4ByteChars,
        },
    },
};

const viralGuards = validators.viral;

if (displayCAConfirm) {
    viralGuards.taf_confirm = { required: false, isChecked: true };
}

range(1, maxFields + 1).forEach((fieldNum) => {
    viralGuards['to_name' + fieldNum] = {
        ...viralGuards['to_name' + fieldNum],

        requiresField: 'to_email' + fieldNum,
    };
    viralGuards['to_email' + fieldNum] = {
        ...viralGuards['to_email' + fieldNum],

        requiresField: 'to_name' + fieldNum,
        external: [isEmail],
    };
});

function getFilledToEmailFields (obj) {
    return  Object.entries(obj)
    .filter(([key]) => key.startsWith('to_email'))
    // Filter out blanks.
    .filter(([key, email]) => !!email);
}

function passwordRequirements (value, key) {
    const { requirements } = validators.passwordMetaData;
    const failedReqs = Object.keys(requirements).filter(req => !value?.match(requirements[req]));

    if (failedReqs.length) {
        failedReqs.forEach((req) => this.addError(key, req, `failed ${req} test`));
        return false;
    }

    return true;
}

async function noPoBox (value, key, obj) {
    const validation = this;

    // https://regex101.com/r/uEqelu/1
    if ((/\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/gi).test(value)) {
        validation.addError(key, 'no_po_box');
        return false;
    }
    return true;
}

export default validators;
