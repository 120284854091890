import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "large-only grid-display brand-selector"
}
const _hoisted_2 = ["onClick"]

import { ref, onMounted, nextTick, useTemplateRef } from 'vue';
    import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
    import * as Brands from '@components/logos';
    import config from 'rocketship-config';

    
export default {
  __name: 'BrandsSelector',
  props: {
        versions: {
            type: Object,
            default () {
                return {};
            },
            required: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        omitBrand: {
            type: String,
            default: '',
        },
        preferredBrand: {
            type: [String, undefined],
            default: undefined,
        },
        useGrid: {
            type: Boolean,
            default: false,
        },
    },
  emits: ['selection'],
  setup(__props, { emit: __emit }) {

    const emit = __emit;

    

    const optionsDefaults = {
        type: 'loop',
        arrows: false,
        perPage: 5,
        autoWidth: true,
        pagination: true,
        trimSpace: true,
        // drag: 'free',
        snap: true,
        focus: 'center',
        waitForTransition: true,
        updateOnMove: true,
        start: 0,
        ...__props.options,
    };

    const hasBeenSelected = ref(false);
    const brandSplide = useTemplateRef('brandSplide');
    const { brands } = {"_public":true,"brands":[{"brand_name":"arrowhead","url":"arrowhead","lp_id":"arrowhead","formatted":"Arrowhead","short":["a","ah"],"meta_tracking":1559367464455615,"active":1},{"brand_name":"deerpark","url":"deerpark","lp_id":"deer","formatted":"Deer Park","short":["dp"],"meta_tracking":214191563060347,"active":1},{"brand_name":"icemountain","url":"icemountain","lp_id":"ice","formatted":"Ice Mountain","short":["im"],"meta_tracking":223352885490785,"active":1},{"brand_name":"ozarka","url":"ozarka","lp_id":"ozarka","formatted":"Ozarka","short":["oz"],"meta_tracking":1378266155691157,"active":1},{"brand_name":"purelife","url":"purelife","lp_id":"pure","formatted":"Pure Life","short":["pl"],"meta_tracking":751400232650294,"active":1},{"brand_name":"polandspring","url":"polandspring","lp_id":"poland","formatted":"Poland Spring","short":["ps"],"meta_tracking":2996036443781632,"active":1},{"brand_name":"saratoga","url":"saratoga","lp_id":"saratoga","formatted":"Saratoga","short":["sa"],"active":0},{"brand_name":"splash","url":"splash","lp_id":"splash","formatted":"Splash Refresher","short":["sp"],"meta_tracking":756962224955686,"active":1},{"brand_name":"zephyrhills","url":"zephyrhills","lp_id":"zephyr","formatted":"Zephyrhills","short":["zh"],"meta_tracking":581542643298772,"active":1}]};
    const filteredBrands = brands.filter((brand) => brand.lp_id !== __props.omitBrand && brand.active > 0);

    const currentBrandIndex = ref(0);
    const currentBrand = ref(brands[currentBrandIndex.value].lp_id);

    onMounted(() => {
        nextTick(() => {
            if (__props.preferredBrand && __props.preferredBrand === 'defaultSelect') {
                brandSplide.value.go(currentBrandIndex.value);
                currentBrand.value = filteredBrands[0].lp_id;
                emit('selection', currentBrand.value);
            }
            else if (__props.preferredBrand && __props.preferredBrand !== 'no_brand') {
                const brandIndex = filteredBrands.findIndex((brand) => brand.lp_id === __props.preferredBrand);
                currentBrandIndex.value = brandIndex;
                brandSplide.value.go(currentBrandIndex.value);
                currentBrand.value = filteredBrands[currentBrandIndex.value].lp_id;
                emit('selection', currentBrand.value);
            }
        });
    });

    const onSplideMove = (splide, slideIndex) => {
        if (slideIndex < 0) {
            slideIndex += filteredBrands.length;
        }

        currentBrandIndex.value = slideIndex;
        currentBrand.value = filteredBrands[slideIndex].lp_id;
        emit('selection', currentBrand.value);
        hasBeenSelected.value = true;
    };
    const onBrandClick = (splide, { index }) => {
        brandSplide.value.go(index);
        hasBeenSelected.value = true;
        emit('selection', currentBrand.value);
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.useGrid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredBrands), (brand, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `brand-${index}`,
              name: "brand",
              onClick: $event => (onSplideMove(null, index))
            }, [
              (brand.active)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["brand-img-wrapper", { active: index === currentBrandIndex.value }])
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Brands[brand.brand_name]), {
                      version: __props.versions[brand.brand_name] || undefined
                    }, null, 8, ["version"]))
                  ], 2))
                : _createCommentVNode("", true)
            ], 8, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(Splide), {
      ref_key: "brandSplide",
      ref: brandSplide,
      class: _normalizeClass(["brand-selector is-overflow", { 'small-only': __props.useGrid }]),
      options: optionsDefaults,
      "has-track": false,
      "aria-label": "Brand Selector",
      "onSplide:click": onBrandClick,
      "onSplide:moved": onSplideMove
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(SplideTrack), null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredBrands), (brand, index) => {
              return (_openBlock(), _createBlock(_unref(SplideSlide), {
                key: `brand-${index}`,
                name: "brand"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["brand-img-wrapper", { 'has-selected': hasBeenSelected.value, active: index === currentBrandIndex.value }])
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Brands[brand.brand_name]), {
                      version: __props.versions[brand.brand_name] || undefined
                    }, null, 8, ["version"]))
                  ], 2)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}
}

}