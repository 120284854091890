import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/CCPA-Privacy-Options-icon.png'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-links" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = { class: "large-only" }
const _hoisted_6 = ["title"]
const _hoisted_7 = ["href", "title"]
const _hoisted_8 = { class: "small-only" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 1,
  class: "artificial-tests"
}
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["src"]

import { computed } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import RewardsDropLogo from '@components/svgs/RewardsDropLogo.vue';
    import { openPopup } from '../popup';

    
export default {
  __name: 'TheFooter',
  props: {
        promotion: {
            type: String,
            required: false,
            default: '',
        },
        url: {
            type: String,
            required: false,
            default: '',
        },
    },
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    

    const profile = computed(() => store.state.profile);
    const app = computed(() => store.state.app);
    const loggedIn = computed(() => store.getters['profile/loggedIn']);
    const termsCondition = computed(() => `<a href="${app.value.tb2URL}public/fulfillment/terms.pdf" target="_blank">${t('links:terms_condition_copy')}</a>`);

    const logOut = () => store.dispatch('profile/logOut');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(RewardsDropLogo),
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: "/how_it_works",
            title: `${_ctx.$t('links:works_copy')}`
          }, null, 8, _hoisted_3), [
            [_unref(vT), 'links:works_copy']
          ])
        ]),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: app.value.tb2URL + 'public/fulfillment/terms.pdf',
            target: "_blank",
            title: `${_ctx.$t('links:terms_condition_copy')} ${_ctx.$t('links:new_window')}`
          }, null, 8, _hoisted_4), [
            [_unref(vT), 'links:terms_condition_copy']
          ])
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("a", {
            href: "/faq",
            title: _unref(t)('links:faq_condition_copy')
          }, _toDisplayString(_ctx.$t('links:faq_copy')), 9, _hoisted_6)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _ctx.$t('links:privacy_url'),
            title: `${_ctx.$t('links:privacy_copy')} ${_ctx.$t('links:new_window')}`,
            target: "_blank"
          }, _toDisplayString(_ctx.$t('links:privacy_copy')), 9, _hoisted_7)
        ]),
        _createElementVNode("li", _hoisted_8, [
          _createElementVNode("a", {
            href: "#/faq",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.$t('links:faq_copy')), 1)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("li", null, [
          _createElementVNode("button", {
            id: "ot-sdk-btn",
            class: "ot-sdk-show-settings"
          }, [
            _createTextVNode(" Your Privacy Choices "),
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            })
          ])
        ], -1))
      ])
    ]),
    (__props.promotion)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "disclaimer",
          innerHTML: _unref(t)(`${__props.promotion}_disclaimer`, { promoUrl: __props.url, siteUrl: app.value.tb2URL, interpolation: { escapeValue: false } })
        }, null, 8, _hoisted_9))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "disclaimer",
      innerHTML: _unref(t)('disclaimer', { termsCondition: termsCondition.value, interpolation: { escapeValue: false } })
    }, null, 8, _hoisted_10),
    (app.value.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_unref(t)('testNamespace:testKey'))
          }, null, 8, _hoisted_12),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_13)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}