import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "optin-wrapper" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 0,
  class: "pre-headline"
}
const _hoisted_4 = { class: "disclaimer full-width" }

import { ref } from 'vue';
    import { useComponent } from '@composables/component';
    import { BaseToggle } from '@components/form';

    
export default {
  __name: 'PromotionOptin',
  props: {
        data: {
            type: Object,
            required: true,
        },
    },
  emits: ['update'],
  setup(__props, { emit: __emit }) {

    const emit = __emit;

    

    const value = ref(__props.data.autoOptin);
    const promoName = __props.data.name.split('_')[1];

    const sendUpdate = (newValue) => {
        emit('update', { name: __props.data.name });
    };

    if (value.value) {
        sendUpdate();
    }

    const { t, vT } = useComponent(['promotions']);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_unref(promoName), "promotion-optin-item"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _unref(t)(`${_unref(promoName)}.optin.src`),
        alt: _unref(t)(`${_unref(promoName)}.optin.alt`)
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, [
        (`${_unref(promoName)}.optin.pre_headline`)
          ? _withDirectives((_openBlock(), _createElementBlock("h3", _hoisted_3, null, 512)), [
              [_unref(vT), `${_unref(promoName)}.optin.pre_headline`]
            ])
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("h2", null, null, 512), [
          [_unref(vT), `${_unref(promoName)}.optin.headline`]
        ]),
        _withDirectives(_createElementVNode("p", null, null, 512), [
          [_unref(vT), `${_unref(promoName)}.optin.body`]
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([{ 'visually-hidden': __props.data.autoOptin }, "full-width opt-in-container"])
      }, [
        _createVNode(_unref(BaseToggle), {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((value).value = $event)),
          name: __props.data.name,
          label: _unref(t)(`optin_modal.${_unref(promoName)}`, { rulesUrl: __props.data.siteUrl }),
          onUpdate: sendUpdate
        }, null, 8, ["modelValue", "name", "label"])
      ], 2),
      _withDirectives(_createElementVNode("p", _hoisted_4, null, 512), [
        [_unref(vT), { key: `${_unref(promoName)}.optin.disclaimer`, rulesUrl: __props.data.siteUrl }]
      ])
    ])
  ], 2))
}
}

}