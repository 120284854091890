import { useModel as _useModel, mergeModels as _mergeModels } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value", "accept"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "error-message aca-error-message",
  role: "alert",
  "aria-live": "assertive"
}

import { onMounted, nextTick, useTemplateRef, computed } from 'vue';

    
export default /*@__PURE__*/Object.assign({ inheritAttrs: false }, {
  __name: 'BaseFileInput',
  props: /*@__PURE__*/_mergeModels({
        label: {
            type: String,
            required: true,
            default: '',
        },
        isError: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
            default: '',
        },
        error: {
            type: String,
            required: true,
            default: '',
        },
        accept: {
            type: String,
            required: true,
            default: '',
        },
        buttonLabel: {
            type: String,
            required: true,
            default: '',
        },
        buttonClass: {
            type: String,
            required: true,
            default: '',
        },
        maxFileSize: {
            type: Number,
            required: true,
            default: 0,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    }, {
    "modelValue": { type: String, required: true },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['selected'], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

    const emit = __emit;

    
    const props = __props;

    const input = useTemplateRef('inputFile');

    const uploadableTypes = computed(() => props.accept);

    onMounted(() => {
        nextTick(() => {
            if (input.value.attributes.autofocus) {
                input.value.focus();
            }
        });
    });

    const browseClick = () => {
        input.value.click();
    };

    const updateFile = (event) => {
        const accepted = props.accept,
              reader = new FileReader(),
              file = event.target.files[0],
              nameSplit = file.name.split('.'),
              extension = nameSplit.at(-1).toLowerCase(),
              fileSize = file.size,
              fileName = file.name;

        if (!accepted.includes(extension)) {
            emit('selected', { error: 'type' });
        }
        else if (fileSize > props.maxFileSize) {
            emit('selected', { error: 'size' });
        }
        else {
            reader.onload = (evt) => {
                emit('selected', { photo: evt.target.result, name: fileName, file });
            };
            reader.readAsDataURL(file);
        }
    };

    const model = _useModel(__props, "modelValue");


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-file-input field", { error: props.isError }])
  }, [
    _createElementVNode("label", { for: __props.name }, _toDisplayString(props.label), 9, _hoisted_1),
    _createElementVNode("input", _mergeProps(_ctx.$attrs, {
      id: __props.name,
      ref: "inputFile",
      name: __props.name,
      value: model.value,
      accept: uploadableTypes.value,
      type: "file",
      onChange: updateFile
    }), null, 16, _hoisted_2),
    _createElementVNode("button", {
      class: _normalizeClass(props.buttonClass),
      disabled: props.disabled,
      onClick: _withModifiers(browseClick, ["prevent"])
    }, _toDisplayString(props.buttonLabel), 11, _hoisted_3),
    (props.isError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.error), 1))
      : _createCommentVNode("", true)
  ], 2))
}
}

})