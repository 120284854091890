import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

import { reactive, ref, createApp, inject, computed, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';

    import RegisterStep from '@components/form/RegisterStep.vue';
    import { register as validatorGuards } from '../../../lib/validation/client-validators';

    import { setRecaptchaV3Challenge } from '../recaptcha';
    import openModal, { ErrorModal, BrandSelectionModal } from '../modals';

    
export default {
  __name: 'RegisterForm',
  emits: ['registered', 'duplicate-account', 'ineligible-age', 'login'],
  setup(__props, { emit: __emit }) {

    useI18next(['registration', 'profile', 'intro', 'buttons']);

    const emit = __emit;

    const store = useStore();
    const profile = computed(() => store.state.profile);
    const router = useRouter();

    const regSplide = ref();

    const tempProfile = reactive({
        email: profile.value.email || '',
        first_name: '',
        last_name: '',
        password: '',
        program_optin: false,
        rules: false,
        get_to_goal_optin: false,
        state: '',
        zip: profile.value.zip,
        account_number: profile.value.account_number,
    });

    const currentStep = ref(0);
    const errorMessage = ref('');
    const errors = ref([]);
    const loading = ref(false);
    const webAnalytics = inject('$webAnalytics');

    const steps = [
        {
            fields: [
                {
                    type: 'InputWithValidation',
                    name: 'email',
                },
                {
                    type: 'InputWithValidation',
                    name: 'first_name',
                },
                {
                    type: 'InputWithValidation',
                    name: 'last_name',
                },
                {
                    type: 'InputWithValidationWithTooltip',
                    name: 'account_number',
                    locked: 'isReadyRefreshRegistration',
                },
            ],
        },
        {
            fields: [
                {
                    type: 'StateSelect',
                    name: 'state',
                },
                {
                    type: 'InputWithValidation',
                    name: 'zip',
                    locked: 'isReadyRefreshRegistration',
                },
            ],
        },
        {
            fields: [
                {
                    type: 'PasswordInputWithValidation',
                    name: 'password',
                    requirements: [
                        'minChars',
                        'minLetters',
                        'minNums',
                        'specialChars',
                    ],
                },
            ],
        },
        {
            fields: [
                {
                    type: 'CheckboxWithValidation',
                    name: 'rules',
                },
                {
                    type: 'CheckboxWithValidation',
                    name: 'get_to_goal_optin',
                    uiFeature: 'get-to-goal',
                },
                {
                    type: 'CheckboxWithValidation',
                    name: 'program_optin',
                },
            ],
        },
    ];

    createApp(() => {
        store.dispatch('profile/logOut');
    });

    onMounted(() => {
        store.commit('ui/updateLeaveCheck', true);
    });

    const updateStep = (direction) => {
        currentStep.value += Number(direction);

        if (currentStep.value > steps.length - 1) {
            currentStep.value = steps.length - 1;
            doRegister();
        }
        else {
            regSplide.value.go(direction);
        }
    };

    const updateValidation = (validation) => {
        console.log('current:', validation);
    };

    const doRegister = async () => {
        errorMessage.value = '';
        errors.value = [];

        if (tempProfile.state && !tempProfile.country) {
            const state = tempProfile.state.split('-');

            if (state.length > 1) {
                tempProfile.country = state[0];
            }
        }
        if (!tempProfile.country) tempProfile.country = 'US';

        tempProfile.x_channel = store.state.app.channel || undefined;
        tempProfile.affiliate_id = store.state.app.affiliateId || undefined;
        tempProfile.locale = store.state.app.locale || undefined;
        tempProfile.referral_code = store.state.app.referralCode || undefined;
        // Allows us to control client-side recaptcha validation.
        tempProfile.isAutomatedTest = store.state.app.isAutomatedTest;

        /* channel was added to support recording of affiliate_id in LP */
        const
            allowedAffiliateMap = {"cleartheiceenroll":"cleartheiceenroll","pshshockey":"pshshockey","srxbetches":"srxbetches","mlbenroll":"mlbenroll","mlbenroll_2024":"mlbenroll_2024","ahcleartheiceenroll_2025":"ahcleartheiceenroll_2025","1.6_welcome":"1.6_welcome","get_to_goal_c":"get_to_goal_c","zephyrhillsnrdahbpnovsweeps":"get_to_goal_c","srnrdahbpnovsweeps":"get_to_goal_c","purelifenrdahbpnovsweeps":"get_to_goal_c","ozarkanrdahbpnovsweeps":"get_to_goal_c","icemountainnrdahbpnovsweeps":"get_to_goal_c","arrowheadnrdahbpnovsweeps":"get_to_goal_c","deerparknrdahbpnovsweeps":"get_to_goal_c","polandspringnrdahbpnovsweeps":"get_to_goal_c"},
            channel = allowedAffiliateMap[tempProfile.affiliate_id];

        if (channel) {
            tempProfile.channel = channel;
        }

        await setRecaptchaV3Challenge({ data: tempProfile, action: 'register' });

        loading.value = true;

        webAnalytics.track('Valid Submission', { category: 'Register Form' });

        try {
            await store.dispatch('profile/register', { tempProfile });
            loading.value = false;

            if (!profile.value.preferred_brand) {
                openModal(BrandSelectionModal);
            }

            // only go to root route when on landing page to route to correct "page"
            if (router.currentRoute.value.name?.includes('landing') ||  router.currentRoute.value.name === 'registration' || router.currentRoute.value.name === 'signin') {
                router.replace('/');
            }

            emit('registered');
        }
        catch (error) {
            loading.value = false;
            const { response } = error;

            if (response && response.status === 409) {
                store.commit('profile/updateProfile', { ...tempProfile, duplicate_account: true });
                emit('duplicate-account');
            }
            else if (response && response.data && response.data.errors) {
                currentStep.value = 0;
                regSplide.value.go(0);

                errors.value = response.data.errors;
                const hasReadyRefreshError = (errors.value.filter((error) => error.name === 'account_number' || error.name === 'zip').length === 2 || errors.value.find((error) => error.name === 'account_number' && error.error === 'limited'));
                errorMessage.value = (hasReadyRefreshError) ? 'error_message_for_ready_refresh' : 'error_alert';
            }
            else {
                openModal(ErrorModal);
            }
        }
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["register-form", `reg-slides-${currentStep.value}`])
  }, [
    _createVNode(_unref(Splide), {
      ref_key: "regSplide",
      ref: regSplide,
      options: {
                rewind: true,
                autoHeight: true,
                arrows: false,
                pagination: false,
                drag: false,
                flickMaxPages: 0,
            },
      "has-track": false,
      "aria-label": "Registration Steps"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(SplideTrack), null, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(steps, (step, index) => {
              return _createVNode(_unref(SplideSlide), {
                key: `register-step-${index}`,
                name: "step"
              }, {
                default: _withCtx(() => [
                  (index <= currentStep.value)
                    ? (_openBlock(), _createBlock(RegisterStep, {
                        key: 0,
                        modelValue: tempProfile,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((tempProfile) = $event)),
                        validators: _unref(validatorGuards),
                        step: index,
                        total: steps.length,
                        current: currentStep.value,
                        fields: step.fields,
                        loading: loading.value,
                        error: errorMessage.value,
                        errors: errors.value,
                        class: "register-step",
                        onOnNext: _cache[1] || (_cache[1] = $event => (updateStep('+1'))),
                        onOnPrev: _cache[2] || (_cache[2] = $event => (updateStep('-1'))),
                        onLogin: _cache[3] || (_cache[3] = $event => (_ctx.$emit('login'))),
                        onValid: updateValidation
                      }, null, 8, ["modelValue", "validators", "step", "total", "current", "fields", "loading", "error", "errors"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            }), 64))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512)
  ], 2))
}
}

}