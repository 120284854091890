import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { name: "user_info" }
const _hoisted_2 = ["disabled"]

import { reactive, ref } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import { RenderlessValidator } from 'rocketship-validator';
    import { InputWithValidation } from '@components/form';
    import { forgotPassword as validatorGuards } from '../../../../lib/validation/client-validators';

    
export default {
  __name: 'EmailForm',
  props: {
        showUnsubscribe: {
            type: Boolean,
            default: false,
        },
    },
  emits: ['submitted'],
  setup(__props, { emit: __emit }) {

    const loading = ref(0);
    const { t, vT } = useI18next(['profile', 'forgot_password']);

    const emit = __emit;
    const isValid = ref(false);
    const field = ref(null);

    

    const store = useStore();

    const formFields = reactive({
        ...store.state.profile,
    });

    const submit = async () => {
        loading.value = true;
        await field.value.validate();
        isValid.value = field.value.isValid;
        if (!isValid.value) {
            loading.value = false;
            return;
        }
        emit('submitted', formFields);
        loading.value = false;
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(RenderlessValidator), {
      ref_key: "field",
      ref: field,
      guards: _unref(validatorGuards),
      data: formFields,
      class: "forgot-password-form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          id: "email_form",
          name: "email_form",
          autocomplete: "",
          novalidate: "",
          "data-e2e": "email-form",
          onSubmit: _withModifiers(submit, ["prevent"])
        }, [
          _createElementVNode("fieldset", _hoisted_1, [
            _createVNode(_unref(InputWithValidation), {
              modelValue: formFields.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((formFields.email) = $event)),
              type: "email",
              error: _ctx.$t('profile:ada.errors.email'),
              label: "Email Address",
              name: "email",
              validator: _unref(validatorGuards).email,
              onValidation: _ctx.handleValidation
            }, null, 8, ["modelValue", "error", "validator", "onValidation"])
          ]),
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass(["btn btn_submit", { 'loading': loading.value }]),
            disabled: !formFields.email,
            "data-e2e": "login-form-submit"
          }, _toDisplayString(__props.showUnsubscribe ? _ctx.$t('buttons:unsubscribe') : _ctx.$t('buttons:submit')), 11, _hoisted_2)
        ], 32)
      ]),
      _: 1
    }, 8, ["guards", "data"])
  ]))
}
}

}