const campaignInfo = {
    deerparkallstar: 'MLB_All_Star',
    purelife: 'Pure_life_project',
};

// TODO: Refactor to accept Object with some default values, so we don't have to send duplicate things all the time
export const trackImpression = (promoName, event, section, campaign_type) => {
    window.dataLayer.push({
        event,
        section,
        campaign_info: campaignInfo[promoName] || promoName,
        campaign_type,
    });
};

// Example usage
// dataLayer.push(
//     {
//         event: 'campaign_impression',
//         section: 'hero_banner',
//         campaign_info: 'all_star',
//         campaign_type: 'redirect’
//     }
// );


export const trackClick = (promoName, event, section, campaign_type, click_text, click_url) => {
    window.dataLayer.push(
        {
            event,
            section,
            campaign_info: campaignInfo[promoName],
            campaign_type,
            click_text,
            click_url,
        },
    );
};

export const trackRulesClick = (e, promoName, event, section, campaign_type) => {
    if (!e.target.attributes.href) {
        return;
    }
    trackClick(promoName, event, section, campaign_type, e.target.innerText, e.target.href);
};

export const addMetaTracking = (meta_tracking) => {
    const metaTrackingScript = document.createElement('script');
    const metaTrackingNoScript = document.createElement('noscript');
    const metaTrackingPixel = document.createElement('img');

    metaTrackingScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${meta_tracking}');
        fbq('track', 'PageView');
    `;

    metaTrackingPixel.src = `https://www.facebook.com/tr?id=${meta_tracking}&ev=PageView&noscript=1`;
    metaTrackingPixel.width = '1';
    metaTrackingPixel.height = '1';
    metaTrackingPixel.style.display = 'none';

    metaTrackingNoScript.appendChild(metaTrackingPixel);

    document.body.appendChild(metaTrackingScript);
    document.body.appendChild(metaTrackingNoScript);
};
