
import { onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, toValue } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { trackImpression, trackClick } from '@utils/tracking';
import * as modals from '../modals';
import { page } from '../plugins/WebAnalytics';


export function useBasePage (pageName, pageClasses = []) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const openLinkModal = (e) => {
        if (!e.target.attributes.href || e.target.attributes.href.value.startsWith('http')) {
            return;
        }
        e.preventDefault();
        const whichModal = e.target.attributes.href.value.replace('#', '');

        if (!modals[whichModal]) {
            console.log(whichModal, 'does not exist, or double check the spelling');
            return;
        }

        store.commit('ui/dialogOpen', { dialogName: whichModal, isModal: true });
    };

    onBeforeMount(() => {
        store.commit('ui/pageEnter', {
            pageName: toValue(pageName),
            pageClasses: toValue(pageClasses),
        });

        const { query: { affiliate_id } } = route;

        // If there was an affiliate in the "query string" embedded in the Vue
        // Router hash portion of the URL, pass it along to the app store.
        if (affiliate_id) {
            store.commit('app/hashAffiliateUpdate', affiliate_id);
        }

        page();
    });

    onMounted(() => {
        document.body.setAttribute('data-e2e-page', toValue(pageName));
    });

    onBeforeUnmount(() => {
        store.commit('ui/pageLeave');
    });

    onUnmounted(() => {
        document.body.removeAttribute('data-e2e-page');
    });

    return {
        pageName,
        pageClasses,
        store,
        router,
        route,
        openLinkModal,
        trackImpression,
        trackClick,
    };
}
