import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-copy" }
const _hoisted_2 = { class: "disclaimer" }
const _hoisted_3 = { class: "points-break-down" }

import { ref, computed } from 'vue';
    import { useComponent } from '@composables/component';
    import { useDialog } from '@composables/dialog';

    const dialogName = 'PointsModal';
    
export default {
  __name: 'PointsModal',
  setup(__props) {

    const rootEl = ref();
    const { close, cancel } = useDialog(dialogName, rootEl);

    const { vT, t, store } = useComponent(['modal_content', 'buttons']);
    const app = computed(() => store.state.app);



return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "rootEl",
    ref: rootEl,
    class: "points-dialog",
    onClick: _cache[2] || (_cache[2] = (...args) => (_unref(cancel) && _unref(cancel)(...args)))
  }, [
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = (event) => event.stopPropagation())
    }, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("h2", null, null, 512), [
          [_unref(vT), 'points.headline_copy']
        ]),
        _withDirectives(_createElementVNode("p", null, null, 512), [
          [_unref(vT), 'points.body_copy']
        ]),
        _withDirectives(_createElementVNode("p", _hoisted_2, null, 512), [
          [_unref(vT),  { key: 'points.disclaimer', siteUrl: app.value.tb2URL }]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_unref(t)('points.40_drops.body_copy')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_unref(t)('points.40_drops.points')), 1)
          ]),
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_unref(t)('points.50_drops.body_copy')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_unref(t)('points.50_drops.points')), 1)
          ]),
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_unref(t)('points.25_drops.body_copy')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_unref(t)('points.25_drops.points')), 1)
          ]),
          _createElementVNode("li", null, [
            _createTextVNode(_toDisplayString(_unref(t)('points.75_drops.body_copy')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_unref(t)('points.75_drops.points')), 1)
          ])
        ]),
        _withDirectives(_createElementVNode("button", {
          class: "btn",
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_unref(close)()), ["prevent"]))
        }, null, 512), [
          [_unref(vT), 'buttons:close']
        ])
      ])
    ])
  ], 512))
}
}

}