const enabled = false;
const variant = "a";
import { makeCall } from '@utils/api';
import { createFeatureFlags } from '@roclab/feature-flags';

const { fetchActiveFeatureFlags } = createFeatureFlags();

const getDefaultState = () => ({
    pageName: 'default',
    pageClasses: [],
    modalName: null,
    rapidTemplate: enabled && variant,
    reduceAnimation: false,
    isSticky: true,
    isReg: true,
    leaveCheck: false,
    homeCarousel: [],
    activeFeatureFlags: {},
    isReadyRefreshRegistration: false,
    uiFeatures: {},
});

const state = getDefaultState();

const getters = {
    // convenience getter for elements that need to know when modals are open/closed
    // such as adding dynamic attributes to help create modal locks (inert, aria-hidden, etc).
    // ex: :aria-hidden="String(this.$store.getters['ui/isModalOpen'])" vs
    // :aria-hidden="String(!!this.$store.state.ui.modalName)"
    isModalOpen: (state) => !!state.modalName,
    checkFeatureFlag: (state) => (id) => !!state.activeFeatureFlags[id],
    checkLeaveStatus: (state) => state.leaveCheck,
};

const { body } = document;
const LOADING_CLASS = 'page-loading';

const mutations = {
    pageEnter (state, newPage) {
        if (state?.pageClasses.length) {
            body.classList.remove(...state.pageClasses);
        }

        state.pageName = newPage.pageName;
        state.pageClasses = newPage.pageClasses;

        body.id = state.pageName;

        if (body.classList.contains(LOADING_CLASS)) {
            body.classList.remove(LOADING_CLASS);
        }
        if (state?.pageClasses.length) {
            body.classList.add(...state.pageClasses);
        }
    },
    pageLeave (state) {
        body.classList.add(LOADING_CLASS);
    },

    modalOpen (state, newModal) {
        state.modalName = newModal.modalName;
    },
    modalClose (state) {
        state.modalName = null;
    },
    dialogOpen (state, { dialogName, isModal }) {
        state.dialogName = dialogName;
        state.isDialogModal = isModal;
    },

    dialogClose (state) {
        state.dialogName = null;
        state.dialogIsModal = false;
    },
    updateAnimation (state) {
        state.reduceAnimation = !state.reduceAnimation;
    },
    updateSticky (state) {
        state.isSticky = !state.isSticky;
    },
    showRegOrLogin (state, isReg) {
        state.isReg = isReg;
    },
    updateHomeCarousel (state, homeCarousel) {
        state.homeCarousel = homeCarousel;
    },
    updateLeaveCheck (state, leaveCheck) {
        state.leaveCheck = leaveCheck;
    },
    updateFeatureFlags (state, flags) {
        state.activeFeatureFlags = {
            ...flags,
        };
    },

    updateState (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                state[key] = values[key];
            }
        }
    },
};

const actions = {
    updateRegLogin ({ state, commit, dispatch }, { isReg, isSticky }) {
        commit('updateState', {
            isReg,
            isSticky: (typeof isSticky === 'boolean') ? isSticky : !state.isSticky,
        });
    },

    async getHomeCarousel ({ commit, rootState }) {
        try {
            const { data: { slides } } = await makeCall({
                type: 'get',
                endpoint: `ui-content/home-carousel/${rootState.profile.preferred_brand}`,
            });

            commit('updateHomeCarousel', slides);
        }
        catch (error) {
            console.error('Error:', error);
        }
    },

    async getActiveUiElements ({ commit, rootState }) {
        try {
            const { data } = await makeCall({
                type: 'get',
                endpoint: 'ui-content',
            });
            commit('updateState', { uiFeatures: data });
        }
        catch (error) {
            console.error('Error:', error);
        }
    },

    async getFeatureFlags ({ commit }) {
        const data = await fetchActiveFeatureFlags();
        commit('updateFeatureFlags', data.activeFeatureFlags);
    },

    async getReadyRefreshRegistrationData ({ commit, dispatch }, id) {
        try {
            const { data } = await makeCall({
                type: 'get',
                endpoint: `ready-refresh/${id}`,
            });

            const { account_number, email, zip } = data;

            commit('updateState', { isReadyRefreshRegistration: true });
            commit('profile/updateProfile', { account_number, email, zip }, { root: true });
        }
        catch (error) {
            console.error('Error', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
