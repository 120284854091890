import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@public/img/scan-receipt.svg'


const _hoisted_1 = {
  key: 0,
  class: "error error-message"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["alt"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 1,
  class: "veryfi-content"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BaseFileInput = _resolveComponent("BaseFileInput")
  const _component_TheDrop = _resolveComponent("TheDrop")
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-overlay receipt-uploader", { confirmation: $data.currentPage === 3 }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal", $data.flow[$data.currentPage]]),
      role: "dialog"
    }, [
      ($data.flow[$data.currentPage] == 'upload')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _withDirectives(_createElementVNode("h2", null, null, 512), [
              [_directive_t, 'file_uploader:headline_copy']
            ]),
            _withDirectives(_createElementVNode("p", null, null, 512), [
              [_directive_t, 'file_uploader:body_copy']
            ]),
            ($data.photoError)
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($setup.t(`file_uploader:error_${$data.photoError}`) || $setup.t('file_uploader:error_service')), 1))
              : _createCommentVNode("", true),
            false
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "scan-receipt",
                  disabled: $data.disabledBtn,
                  onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.openScanner && $options.openScanner(...args)), ["prevent"]))
                }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: $setup.t('buttons:scan_receipt')
                  }, null, 8, _hoisted_3),
                  _withDirectives(_createElementVNode("p", null, null, 512), [
                    [_directive_t, 'buttons:scan_receipt']
                  ])
                ], 8, _hoisted_2))
              : _createCommentVNode("", true),
            _createVNode(_component_BaseFileInput, {
              modelValue: $data.fileObject,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.fileObject) = $event)),
              name: "photo",
              label: 'Select photo',
              accept: $data.photoFileTypes,
              "button-label": $setup.t('buttons:select_file'),
              "button-class": 'secondary button',
              "max-file-size": $data.photoUploadSizeMax,
              "is-error": $data.photoError != '',
              disabled: $data.disabledBtn,
              onSelected: $options.openPreview
            }, null, 8, ["modelValue", "accept", "button-label", "max-file-size", "is-error", "disabled", "onSelected"]),
            _withDirectives(_createElementVNode("h3", null, null, 512), [
              [_directive_t, 'file_uploader:tips_headline']
            ]),
            _createElementVNode("ul", null, [
              _withDirectives(_createElementVNode("li", null, null, 512), [
                [_directive_t, 'file_uploader:tip_01']
              ]),
              _withDirectives(_createElementVNode("li", null, null, 512), [
                [_directive_t, 'file_uploader:tip_02']
              ]),
              _withDirectives(_createElementVNode("li", null, null, 512), [
                [_directive_t, 'file_uploader:tip_03']
              ]),
              _withDirectives(_createElementVNode("li", null, null, 512), [
                [_directive_t, 'file_uploader:tip_04']
              ]),
              _withDirectives(_createElementVNode("li", null, null, 512), [
                [_directive_t, 'file_uploader:tip_05']
              ])
            ])
          ], 64))
        : ($data.flow[$data.currentPage] == 'preview')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _withDirectives(_createElementVNode("h2", null, null, 512), [
                [_directive_t, 'file_uploader:preview.headline_copy']
              ]),
              ($data.previewImg || $data.hasPdf)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "preview-img",
                    src: $data.hasPdf ? $data.defaultPdfSrc : $data.previewImg.photo,
                    alt: "Receipt Preview"
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "secondary btn",
                disabled: !$data.previewImg || _ctx.loading,
                onClick: _cache[2] || (_cache[2] = (...args) => ($options.uploadFile && $options.uploadFile(...args)))
              }, _toDisplayString($setup.t('buttons:looks_good')), 9, _hoisted_5),
              _createElementVNode("p", null, [
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[3] || (_cache[3] = _withModifiers($event => ($data.currentPage--), ["prevent"]))
                }, _toDisplayString($setup.t('buttons:try_again')), 1)
              ])
            ], 64))
          : ($data.flow[$data.currentPage] == 'error')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _withDirectives(_createElementVNode("h2", null, null, 512), [
                  [_directive_t, 'file_uploader:error.headline_copy']
                ]),
                _withDirectives(_createElementVNode("p", null, null, 512), [
                  [_directive_t, 'file_uploader:error.body_copy']
                ]),
                _createElementVNode("ul", null, [
                  _withDirectives(_createElementVNode("li", null, null, 512), [
                    [_directive_t, 'file_uploader:error.receipt_contains_01']
                  ]),
                  _withDirectives(_createElementVNode("li", null, null, 512), [
                    [_directive_t, 'file_uploader:error.receipt_contains_02']
                  ]),
                  _withDirectives(_createElementVNode("li", null, null, 512), [
                    [_directive_t, 'file_uploader:error.receipt_contains_03']
                  ]),
                  _withDirectives(_createElementVNode("li", null, null, 512), [
                    [_directive_t, 'file_uploader:error.receipt_contains_04']
                  ]),
                  _withDirectives(_createElementVNode("li", null, null, 512), [
                    [_directive_t, 'file_uploader:error.receipt_contains_05']
                  ]),
                  _withDirectives(_createElementVNode("li", null, null, 512), [
                    [_directive_t, 'file_uploader:error.receipt_contains_06']
                  ])
                ]),
                _createElementVNode("button", {
                  class: "button",
                  onClick: _cache[4] || (_cache[4] = _withModifiers($event => ($data.currentPage = 0), ["prevent"]))
                }, _toDisplayString($setup.t('buttons:try_again')), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _withDirectives(_createElementVNode("h2", null, null, 512), [
                  [_directive_t, 'file_uploader:confirmation.headline_copy']
                ]),
                _withDirectives(_createElementVNode("p", null, null, 512), [
                  [_directive_t, 'file_uploader:confirmation.body_copy']
                ])
              ], 64)),
      (!$data.showVeryfi && $data.flow[$data.currentPage] !== 'error' && $data.flow[$data.currentPage] !== 'confirmation')
        ? (_openBlock(), _createElementBlock("button", {
            key: 4,
            class: "secondary button close-x",
            onClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("i", { class: "fal fa-times" }, null, -1),
            _createElementVNode("span", { class: "hidden" }, "Close", -1)
          ])))
        : _createCommentVNode("", true)
    ], 2),
    (!$data.showVeryfi && $data.flow[$data.currentPage] === 'confirmation')
      ? _withDirectives((_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "secondary button confirmation-btn",
          onClick: _cache[6] || (_cache[6] = _withModifiers($event => ($options.closeSuccessModel()), ["prevent"]))
        }, null, 512)), [
          [_directive_t, 'buttons:got_it']
        ])
      : _createCommentVNode("", true),
    ($data.showVeryfi)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { id: "veryfi-container" }, null, -1)),
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = _withModifiers((...args) => ($options.captureImg && $options.captureImg(...args)), ["prevent"]))
          }, [
            _createVNode(_component_TheDrop, { color: '#FFFFFF' })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}