import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "promotion-details-section" }
const _hoisted_2 = {
  key: 0,
  class: "logo-container"
}
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 1,
  class: "sparkle-container"
}
const _hoisted_5 = ["src", "alt"]

import { useI18next } from '@composables/i18next';
    import { useComponent } from '@composables/component';

    
export default {
  __name: 'PromotionDetails',
  props: {
        promotion: {
            type: String,
            default: '',
            required: true,
        },
        rules: {
            type: String,
            default: '',
            required: true,
        },
    },
  setup(__props) {

    
    const { trackRulesClick } = useComponent('promotion_deerparkallstar');
    const { t, vT } = useI18next(`promotion_${__props.promotion}`);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_unref(t)(`assets.details.image_src_1`))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(t)(`assets.details.image_src_1`),
            alt: _unref(t)(`assets.details.image_alt_1`)
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("h2", null, null, 512), [
      [_unref(vT), 'details_box.headline_copy']
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "details-body-copy",
      onClick: _cache[0] || (_cache[0] = $event => (_unref(trackRulesClick)($event, __props.promotion, 'click', 'Promotion Details', 'Promotion')))
    }, null, 512), [
      [_unref(vT), { key: 'details_box.body_copy', rulesUrl: __props.rules }]
    ]),
    (_unref(t)(`assets.details.image_src_2`))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _unref(t)(`assets.details.image_src_2`),
            alt: _unref(t)(`assets.details.image_alt_2`)
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}